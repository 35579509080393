import logo from './longlogo.png';
import './App.css';

function App() {
    return (
    <div className="App">
        {/* <div className="navbar" id="nav">
            <a href="index.html" className="navbar-image-wrap">
                <img src={logo} alt="Logo" className="navbar-image"/>
            </a>
        </div> */}
        {/* <div> */}
            <div className='logo-wrap'>
                <img src={logo} alt="Logo" className="logo-image"/>
            </div>
            <h1>This Site is Under Construction</h1>
            <h2>Something new is coming soon!</h2>
        {/* </div> */}
    </div>
    );
}

export default App;
